import _, { debounce, isEmpty, isFunction } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { v4 as uuid, validate } from 'uuid';
import QCXDanfeWizardFinalForm from '../../../../components/danfe/QCXDanfeWizardFinalForm';
import { fetchById as fetchDanfeById, register, save, validateDanfeStepOne } from '../../../../features/danfe/danfeAPI';
import { selectBackgroundMode, selectDanfe, selectMode, selectStatus } from '../../../../features/danfe/danfeSelectors';
import {
  addToList,
  changeToBackgroundCreateMode,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  failure,
  loading,
  resetBackgroundMode,
  resetModel,
  setModel,
  setRelatedDanfeMae,
  success,
  updateModelAdicoes,
} from '../../../../features/danfe/danfeSlice';
import { fetchByIdAsync } from '../../../../features/danfe/danfeThunks';
import { setErrorFeedback, setSuccessFeedback, setWarningFeedback } from '../../../../features/feedback/feedbackSlice';
import QCXRegistrationFormPageTemplate from '../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';
import { DANFE_FILHOTE, DANFE_NAO_EMITIDA, isDanfeFilhote } from '../../../../utils/general/danfe/danfeUtils';
import {
  isValid,
  normalizeNumeral,
  normalizeZonedDateTime,
  unnormalizeData,
  unnormalizeNumeral,
} from '../../../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../../../utils/hooks/form/field/formatters';
import {
  isBackgroundCreateMode,
  isConsultMode,
  isCreateMode,
  isFailureStatus,
  isIdleStatus,
  isLoadingStatus,
  isNoneMode,
  isPreparingActionStatus,
  isUpdateMode,
} from '../../../../utils/store/store-utils';

export default function DanfeRegistrationPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const danfe = useSelector(selectDanfe);
  const status = useSelector(selectStatus);
  const mode = useSelector(selectMode);
  const backgroundMode = useSelector(selectBackgroundMode);

  const isPreparingAction = useMemo(() => isPreparingActionStatus(status), [status]);

  const isLoading = useMemo(() => isLoadingStatus(status), [status]);

  const isIdle = useMemo(() => isIdleStatus(status), [status]);

  const isFailure = useMemo(() => isFailureStatus(status), [status]);

  const isNone = useMemo(() => isNoneMode(mode), [mode]);

  const isCreate = useMemo(() => isCreateMode(mode), [mode]);

  const isConsult = useMemo(() => isConsultMode(mode), [mode]);

  const isUpdate = useMemo(() => isUpdateMode(mode), [mode]);

  const handleChangeToBackgroundCreate = () => {
    dispatch(changeToBackgroundCreateMode());
  };

  const handleResetBackgroundMode = () => {
    dispatch(resetBackgroundMode());
  };

  const isBackgroundCreate = useMemo(() => isBackgroundCreateMode(backgroundMode), [backgroundMode]);

  const fetchById = useCallback((danfeId) => dispatch(fetchByIdAsync(danfeId)), []);

  const handleChangeToConsult = useCallback(() => {
    dispatch(changeToConsultMode());
  }, []);

  const handleChangeToUpdate = useCallback((additional = {}) => {
    dispatch(changeToUpdateMode());

    if (!isEmpty(additional) && isFunction(additional?.callback)) {
      additional.callback();
    }
  }, []);

  const handleCancelUpdate = useCallback(() => {
    const currentId = isBackgroundCreate ? danfe?.id : id;

    if (currentId) {
      dispatch(fetchByIdAsync(currentId));
    }
    handleChangeToConsult();
  }, [danfe, isBackgroundCreate, id]);

  const handleChangeToCreate = () => {
    dispatch(changeToCreateMode());
  };

  const infoCriaFilhote = useMemo(() => history.location.state?.data, [history]);

  const isFilhote = useMemo(() => isDanfeFilhote(danfe?.tipoDanfe), [danfe]);

  const hasFilhotes = useMemo(() => !isEmpty(danfe?.filhotes), [danfe]);

  useEffect(() => {
    if (!isEmpty(id)) {
      fetchById(id);
      handleChangeToConsult();
    }
  }, [id, fetchById, handleChangeToConsult]);

  const unnormalize = useCallback((normalizedData) => {
    const {
      dataHoraAutorizacao,
      dataEntrada,
      dataEmissao,
      adicoes,
      volumes,
      percentualIcmsDiferido,
      valorIcmsDiferido,
      ...restNormalizedData
    } = normalizedData;

    const unnormalizedData = {
      ...restNormalizedData,
      dataHoraAutorizacao: unnormalizeData(dataHoraAutorizacao),
      dataEntrada: unnormalizeData(dataEntrada),
      dataEmissao: unnormalizeData(dataEmissao),
      ignorableFields: normalizedData?.ignorableFields ?? {
        adi: {},
        emb: {},
      },
      percentualIcmsDiferido: isValid(percentualIcmsDiferido)
        ? unnormalizeNumeral(percentualIcmsDiferido, formatBrazilianNumericDecimal(4))
        : undefined,
      valorIcmsDiferido: isValid(valorIcmsDiferido)
        ? unnormalizeNumeral(valorIcmsDiferido, formatBrazilianNumericDecimal(2))
        : undefined,
      adicoes: (adicoes || [])?.map((adicao) =>
        adicao?.id
          ? {
              ...adicao,
              percentualIcmsDiferido: isValid(adicao?.percentualIcmsDiferido)
                ? unnormalizeNumeral(adicao?.percentualIcmsDiferido, formatBrazilianNumericDecimal(4))
                : undefined,
              valorIcmsDiferido: isValid(adicao?.valorIcmsDiferido)
                ? unnormalizeNumeral(adicao?.valorIcmsDiferido, formatBrazilianNumericDecimal(2))
                : undefined,
            }
          : {
              ...adicao,
              id: uuid(),
              percentualIcmsDiferido: isValid(adicao?.percentualIcmsDiferido)
                ? unnormalizeNumeral(adicao?.percentualIcmsDiferido, formatBrazilianNumericDecimal(4))
                : undefined,
              valorIcmsDiferido: isValid(adicao?.valorIcmsDiferido)
                ? unnormalizeNumeral(adicao?.valorIcmsDiferido, formatBrazilianNumericDecimal(2))
                : undefined,
            }
      ),
      volumes: (volumes || [])?.map((volume) =>
        volume?.id
          ? {
              ...volume,
            }
          : {
              ...volume,
              id: uuid(),
            }
      ),
    };
    // Note que futuramente devemos ter um campo do tipo Storage descrevendo o
    // arquivo xml (é uma mudança planejada, talvez já tenha sido feita). Mas aqui
    // isso não importa, porque o arquivo não é manipulado diretamente pelo usuário.
    return unnormalizedData;
  }, []);

  const normalize = useCallback((unnormalizedData) => {
    const {
      importador,
      transportador,
      di,
      dataHoraAutorizacao,
      dataEntrada,
      dataEmissao,
      veiculoPlaca,
      adicoes,
      volumes,
      icmsDiferido,
      percentualIcmsDiferido,
      valorIcmsDiferido,
      ...restUnnormalizedData
    } = unnormalizedData;

    const normalizedData = {
      ...restUnnormalizedData,
      importador: importador?.id ? importador : null,
      transportador: transportador?.id ? transportador : null,
      di: di?.id ? di : null,
      dataHoraAutorizacao: normalizeZonedDateTime(dataHoraAutorizacao, ''),
      dataEntrada: normalizeZonedDateTime(dataEntrada, '-03:00'),
      dataEmissao: normalizeZonedDateTime(dataEmissao, '-03:00'),
      ...(icmsDiferido && !isEmpty(icmsDiferido) ? { icmsDiferido } : {}),
      percentualIcmsDiferido: percentualIcmsDiferido ? normalizeNumeral(percentualIcmsDiferido) : undefined,
      valorIcmsDiferido: valorIcmsDiferido ? normalizeNumeral(valorIcmsDiferido) : undefined,
      // Guardamos a placa do veículo de transporte sem traços ou espaços
      // porque ele é enviado assim na nota fiscal.
      veiculoPlaca: veiculoPlaca?.replace?.('-', '')?.replace?.(' ', ''),
      // Removemos os ids das adições. Estes ids existem porque a tabela precisa
      // deles. Manter se forem ids numéricos porque significa que eles já
      // existem no banco.
      adicoes: (adicoes || [])?.map((adicao) =>
        validate(adicao?.id)
          ? {
              ...adicao,
              id: null,
              percentualIcmsDiferido: normalizeNumeral(adicao?.percentualIcmsDiferido),
              valorIcmsDiferido: normalizeNumeral(adicao?.valorIcmsDiferido),
              mercadoria: {
                ...adicao?.mercadoria,
                atributos: [],
              },
              adicaoDi: {
                ...adicao?.adicaoDi,
                atributos: [],
                mercadorias: Array.isArray(adicao?.adicaoDi?.mercadorias)
                  ? adicao.adicaoDi.mercadorias.map((mercadoria) => ({
                      ...mercadoria,
                      atributos: [],
                    }))
                  : [],
              },
            }
          : {
              ...adicao,
              percentualIcmsDiferido: normalizeNumeral(adicao?.percentualIcmsDiferido),
              valorIcmsDiferido: normalizeNumeral(adicao?.valorIcmsDiferido),
              mercadoria: {
                ...adicao?.mercadoria,
                atributos: [],
              },
              adicaoDi: {
                ...adicao?.adicaoDi,
                atributos: [],
                mercadorias: Array.isArray(adicao?.adicaoDi?.mercadorias)
                  ? adicao.adicaoDi.mercadorias.map((mercadoria) => ({
                      ...mercadoria,
                      atributos: [],
                    }))
                  : [],
              },
            }
      ),
      // Todos os volumes que tiverem ids que não são números têm seus ids
      // removidos. Os volumes precisam de ids para o componente de tabela.
      volumes: Array.isArray(volumes)
        ? volumes?.map((v) => {
            if (typeof v?.id === 'number' || v?.id?.match?.(/^[0-9]*$/)) {
              // Id é número. Manter.
              return v;
            }
            // Id não é número. Remover.
            return {
              ...v,
              id: undefined,
            };
          })
        : volumes,
    };

    return normalizedData;
  }, []);

  const handleFetchDanfeById = useCallback(
    async (danfeId) => {
      dispatch(loading());

      const responseDanfe = await fetchDanfeById(danfeId);

      if (responseDanfe?.status === 200) {
        const unnormalizedData = unnormalize(responseDanfe?.data);

        const modelFilhote = {
          ...unnormalizedData,
          adicoes: [],
          danfeMae: responseDanfe?.data,
          filhotes: [],
          id: undefined,
          infoAdicional: undefined,
          infoAdicionalCompleta: undefined,
          infoAdicionalCompletaSemFormatacao: undefined,
          insertionDate: undefined,
          nfe: undefined,
          statusNfe: DANFE_NAO_EMITIDA,
          tipoDanfe: DANFE_FILHOTE,
          veiculoPlaca: undefined,
          veiculoUf: undefined,
          volumes: [],
        };

        dispatch(setModel(modelFilhote));
        dispatch(setRelatedDanfeMae(responseDanfe?.data));
      }

      dispatch(success());
    },
    [unnormalize]
  );

  useEffect(() => {
    if (infoCriaFilhote) {
      handleFetchDanfeById(infoCriaFilhote?.danfeMae?.id);
    }
  }, [infoCriaFilhote, handleFetchDanfeById]);

  const handleDispatchSetModel = useCallback((data) => {
    dispatch(setModel(data));
  }, []);

  // Chamado pelo handleSubmit quando estamos criando um item novo.
  const createDanfe = useCallback(
    async (data, step, next) => {
      // const isFirstStep = step === 0;
      const isLastStep = step === 1;

      const executeDebounced = debounce(async () => {
        try {
          // A danfe só é salva no último passo (o segundo passo, de adições).
          if (isLastStep) {
            const response = await register(data, [{ name: 'retorno', value: 1 }]);

            if (response?.status === 200 || response?.status === 201) {
              dispatch(resetModel());

              const handleResultWithDebounce = debounce(() => {
                history.push(t('com.muralis.qcx.url.importacaoDANFE'));

                // A criação de uma nova danfe pode trazer várias na response
                // se houver necessidade de criar várias devido ao agrupamento
                // configurado no modelo de danfe.
                const created = response?.data?.successes;
                const failures = response?.data?.failures;
                if (Array.isArray(failures) && failures?.length > 0) {
                  const warningFlag = '[WARNING] ';
                  if (response?.data?.message?.startsWith(warningFlag)) {
                    const warningMessage = response?.data?.message?.replace(warningFlag, '');
                    dispatch(
                      setWarningFeedback({
                        message: `${warningMessage}.`,
                      })
                    );
                  } else {
                    dispatch(failure());
                    dispatch(
                      setErrorFeedback({
                        message: response?.data?.message,
                      })
                    );
                  }
                } else {
                  dispatch(success());
                  let mensagemDaDanfe = t('com.muralis.qcx.mensagem.registroDANFE', { tipoDanfe: '' });
                  // Nós sempre esperamos que "created" seja um array.
                  if (Array.isArray(created)) {
                    if (created?.length === 1) {
                      mensagemDaDanfe = t('com.muralis.qcx.mensagem.registroDANFEEspecifico', {
                        codigo: created[0]?.code,
                      });
                    } else {
                      mensagemDaDanfe = t('com.muralis.qcx.mensagem.quantidadeDANFERegistradas', {
                        quantidade: created?.length,
                      });
                    }
                  } else if (typeof created?.code === 'string') {
                    mensagemDaDanfe = t('com.muralis.qcx.mensagem.registroDANFEEspecifico', { codigo: created?.code });
                  }
                  dispatch(
                    setSuccessFeedback({
                      message: mensagemDaDanfe,
                    })
                  );
                  // Mudar para modo consulta somente se o registro deu certo.
                  handleChangeToConsult();
                }

                dispatch(addToList({ data: created }));
              });

              handleResultWithDebounce();
            }
          } else {
            // No step 1, fazemos uma chamada a um endpoint que valida algumas
            // informações, para que qualquer erro não aconteça só no final.
            if (infoCriaFilhote) {
              dispatch(success());
              next?.();
              return;
            }

            const response = await validateDanfeStepOne(data);
            if (response?.status === 200) {
              dispatch(success());
              next?.();
            } else {
              dispatch(failure());
              dispatch(
                setSuccessFeedback({
                  message: response?.data?.message,
                })
              );
            }
          }
        } catch (error) {
          dispatch(failure());
          let errorMessage = t('com.muralis.qcx.erro.erroRequisicao');
          if (error?.response && error?.response?.data?.message) {
            errorMessage = t('com.muralis.qcx.erro.erroMensagem', { mensagem: error?.response?.data?.message });
          }
          dispatch(
            setErrorFeedback({
              message: errorMessage,
            })
          );

          dispatch(setModel(unnormalize(data)));
        }
      }, 500);

      dispatch(loading());
      executeDebounced();
    },
    [infoCriaFilhote, t]
  );

  // Chamado pelo handleSubmit quando estamos editando um item que já existe.
  const updateDanfe = useCallback(
    async (data /** , step, next */) => {
      const executeDebounced = debounce(async () => {
        try {
          const response = await save(data);

          if (response?.status === 200 || response?.status === 201) {
            const saved = response?.data;

            if (Array.isArray(saved)) {
              dispatch(success());
              saved.forEach((danfeUp) => {
                dispatch(addToList({ data: danfeUp }));
                dispatch(updateModelAdicoes(danfeUp.adicoes));
              });

              const mensagemDaDanfe = t('com.muralis.qcx.mensagem.DANFESalva', { codigo: saved[0]?.code });
              dispatch(
                setSuccessFeedback({
                  message: mensagemDaDanfe,
                })
              );

              dispatch(setModel(saved[0]));
            }

            handleChangeToConsult();
          }
        } catch (error) {
          dispatch(failure());
          dispatch(
            setErrorFeedback({
              message: t('com.muralis.qcx.erro.erroSalvarDANFE', { mensagem: error?.response?.data?.message }),
            })
          );
        }
      }, 500);

      dispatch(loading());
      executeDebounced();
    },
    [t]
  );

  const handleSubmit = async (data, step, next) => {
    const normalizedData = normalize(data);
    if (isUpdate) {
      await updateDanfe(normalizedData, step);
    } else {
      await createDanfe(normalizedData, step, next);
    }
  };

  const handleAlternativeSave = async (event, step) => {
    if (event && !isEmpty(event)) {
      event.stopPropagation();
    }

    const normalizedData = normalize(danfe);

    if (isUpdate) {
      await updateDanfe(normalizedData, step);
    } else {
      await createDanfe(normalizedData, step);
    }
  };

  const refreshSelectedModel = useCallback(() => {
    if (!isEmpty(danfe) && danfe?.id) {
      fetchById(danfe?.id);
    }
  }, [danfe, isUpdate, fetchById]);

  const actionName = useMemo(() => {
    if (isCreate || isBackgroundCreate) return t('com.muralis.qcx.acoes.novo');
    if (isConsult) return t('com.muralis.qcx.acoes.visualizar');
    return t('com.muralis.qcx.acoes.alterar');
  }, [isCreate, isConsult, isBackgroundCreate, t]);

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloImportacao'),
          name: t('com.muralis.qcx.importacao.label'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.importacaoDANFE'),
          name: t('com.muralis.qcx.DANFE.label'),
        },
      },
      (infoCriaFilhote || isFilhote) && {
        text: {
          name: t('com.muralis.qcx.filhote'),
        },
      },
      {
        text: {
          name: actionName,
        },
      },
    ],
    [actionName, infoCriaFilhote, isFilhote, t]
  );

  const pageTitle = useMemo(
    () =>
      isNone || isCreate
        ? t('com.muralis.qcx.DANFE.novoRegistroDANFE', {
            tipoDanfe: infoCriaFilhote ? t('com.muralis.qcx.filhote').toLowerCase() : '',
          })
        : t('com.muralis.qcx.DANFE.DANFENumero', {
            numero: danfe?.numeroDanfe || '-',
            tipoDanfe: infoCriaFilhote ? t('com.muralis.qcx.filhote').toLowerCase() : '',
          }),
    [isNone, isCreate, danfe, isFilhote, t]
  );

  const model = useMemo(() => unnormalize(danfe), [unnormalize, danfe]);

  return (
    <QCXRegistrationFormPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      isIdle={isIdle}
      isLoading={isLoading}
      isFailure={isFailure}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isBackgroundCreate={isBackgroundCreate}
      isPreparingAction={isPreparingAction}
      handleAlternativeSave={handleAlternativeSave}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      showSubtitle={false}
      authInfo={authInfo}
      disableUpdate={isFilhote || hasFilhotes}
    >
      {(formProps) => (
        <QCXDanfeWizardFinalForm
          model={model}
          isConsult={isConsult}
          isCreate={isCreate}
          isUpdate={isUpdate}
          isBackgroundCreate={isBackgroundCreate}
          handleChangeModel={handleDispatchSetModel}
          handleSubmit={handleSubmit}
          handleChangeToCreate={handleChangeToCreate}
          handleChangeToConsult={handleChangeToConsult}
          handleAlternativeSave={handleAlternativeSave}
          handleChangeToUpdate={handleChangeToUpdate}
          handleCancelUpdate={handleCancelUpdate}
          handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
          handleResetBackgroundMode={handleResetBackgroundMode}
          refreshSelectedModel={refreshSelectedModel}
          authInfo={authInfo}
          requiredRoles={['danfe']}
          danfeNormalizer={normalize}
          {...formProps}
        />
      )}
    </QCXRegistrationFormPageTemplate>
  );
}
