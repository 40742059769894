import { useDispatch, useSelector } from 'react-redux';
import { Grid, makeStyles } from '@material-ui/core';
import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, {
  useMemo,
  useEffect,
  useState,
} from 'react';
import { fetchAllAsync, selectDocumentoAnexacao } from '../../../features/documento-anexacao/documentoAnexacaoSlice';
import QCXAutocompleteSelect from '../../../shared-components/autocomplete-select/QCXAutocompleteSelect';
import QCXFormDocumentoAnexacaoPalavraChave from '../../../shared-components/form-documento-anexacao-palavra-chave/QCXFormDocumentoAnexacaoPalavraChave';
import QCXFormStepWizard from '../../../shared-components/form-step-wizard/QCXFormStepWizard';

export default function QCXDeclaracaoImportacaoDocumentosForm({
  initialValues,
  handleSubmit,
  isConsult,
  isCreate,
  isLocked,
  controlComponentProps,
  ...restProps
}) {
  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      disablePristine
      {...restProps}
    >
      {({ values }) => (
        <>

          <QCXFormDocumentoAnexacaoPalavraChave
            initialValues={values}
            disabled={isConsult}
          />

        </>
      )}
    </QCXFormStepWizard>
  
  );
}
