import { isEmpty, isFunction } from 'lodash';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCalculator, FaFileExcel } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { v4 as uuid } from 'uuid';
import useGetApplicationState from '../../../../../features/application-state/useGetApplicationState';
import { clienteActions } from '../../../../../features/cliente/clienteSlice';
import { IDLE_CONTROL } from '../../../../../features/config-control';
import { selectDeclaracaoImportacao } from '../../../../../features/declaracao-importacao/declaracaoImportacaoSelectors';
import { setErrorFeedback, setSuccessFeedback } from '../../../../../features/feedback/feedbackSlice';
import { servicoActions } from '../../../../../features/servico/servicoSlice';
import { transportadoraActions } from '../../../../../features/transportadora/transportadoraSlice';
import { ActionsBarProps } from '../../../../../shared-components/actions-bar/ActionsBar';
import QCXConfirmDialog from '../../../../../shared-components/dialog/QCXConfirmDialog';
import QCXRegistrationFormPageTemplate from '../../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';
import { normalizeNumeral, unnormalizeNumeral } from '../../../../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../../../../utils/hooks/form/field/formatters';
import useOperationConfirm from '../../../../../utils/hooks/operation/confirm/useOperationConfirm';
import {
  isBackgroundCreateMode,
  isBackgroundDeleteMode,
  isBackgroundUpdateMode,
  isConsultMode,
  isCreateMode,
  isFailureStatus,
  isIdleStatus,
  isLockedMode,
  isNoneMode,
  isPreparingActionStatus,
  isUpdateMode,
} from '../../../../../utils/store/store-utils';
import * as custeiosAPI from '../api/custeiosAPI';
import { selectBackgroundMode, selectCusteio, selectMode, selectStatus } from '../api/custeiosSelectors';
import {
  changeToBackgroundCreateMode,
  changeToBackgroundDeleteMode,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  failure,
  loading,
  preparingAction,
  resetBackgroundMode,
  resetModel,
  setError,
  setModel,
  setResponse,
  success,
  updateOnList,
} from '../api/custeiosSlice';
import { carregaDespesasAsync, fetchByIdAsync, generateSpreadsheetById } from '../api/custeiosThunks';
import QCXCusteiosWizardFinalForm from './components/QCXCusteiosWizardFinalForm';
import { finishLoading, startLoading } from '../../../../../features/application-state/applicationStateSlice';
import FileSaver from 'file-saver';
import { useFormState } from 'react-final-form';
import { setModel as setDIModel } from '../../../../../features/declaracao-importacao/declaracaoImportacaoSlice';
import { selectStatus as selectDIStatus } from '../../../../../features/declaracao-importacao/declaracaoImportacaoSelectors';
import QCXProgressBackdrop from '../../../../../shared-components/backdrop/QCXProgressBackdrop';
import { LOADING_STATUS } from '../../../../../features/status';
import { selectStatus as selectDespesaReceitaStatus } from '../../../../../features/despesa-receita/despesaReceitaSlice';
import { selectStatus as selectFollowUpStatus } from '../../../../../features/follow-up/followUpSelectors';

export default function CusteiosRegistrationPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const params: { id?: string | undefined } = useParams();
  const { id } = params;

  const dispatch = useDispatch();
  const custeio = useSelector(selectCusteio);
  const di = useSelector(selectDeclaracaoImportacao);
  const { isLoading } = useGetApplicationState();

  const fetchById = useCallback((id: string | undefined) => {
    dispatch(fetchByIdAsync(id!));
  }, []);

  useEffect(() => {
    if (id !== undefined) {
      dispatch(changeToConsultMode());
      fetchById(id!);
    } else {
      dispatch(changeToCreateMode());
    }
  }, [id]);

  const calculateTotalDespesas = useCallback((despesas: any) => {
    const totalReal = despesas?.reduce((acc: any, despesa: any) => {
      return acc + despesa?.valorReal;
    }, 0);
    const totalDolar = despesas?.reduce((acc: any, despesa: any) => {
      return acc + despesa?.valorDolar;
    }, 0);
    const totalMoeda = despesas?.reduce((acc: any, despesa: any) => {
      return acc + despesa?.valorMoeda;
    }, 0);
    const totalDespesas = {
      totalReal: isNaN(totalReal) ? 0 : unnormalizeNumeral(totalReal, formatBrazilianNumericDecimal(2)),
      totalDolar: isNaN(totalDolar) ? 0 : unnormalizeNumeral(totalDolar, formatBrazilianNumericDecimal(2)),
      totalMoeda: isNaN(totalMoeda) ? 0 : unnormalizeNumeral(totalMoeda, formatBrazilianNumericDecimal(2)),
    };
    return totalDespesas;
  }, []);

  const unnormalize = useCallback(
    (unnormalizedCusteio: any) => {
      const totalDespesas = calculateTotalDespesas(unnormalizedCusteio?.despesas);
      unnormalizedCusteio = {
        ...unnormalizedCusteio,
        fatura: unnormalizedCusteio?.fatura ?? di,
        cliente: unnormalizedCusteio?.cliente ?? di?.importador,
        followUp: {
          ...unnormalizedCusteio?.followUp,
          referencias: unnormalizedCusteio?.followUp?.referencias?.map((r: any) => {
            if (r.id) {
              return r;
            } else {
              return {
                referencia: r,
                id: uuid(),
              };
            }
          }),
        },
        despesas: unnormalizedCusteio?.despesas?.map((d: any) => {
          return {
            ...d,
            id: d?.id ? d?.id : uuid(),
          };
        }),
      };
      return {
        ...unnormalizedCusteio,
        ignorableFields: {
          ...totalDespesas,
        },
      };
    },
    [custeio, calculateTotalDespesas, di]
  );

  const model = useMemo(() => unnormalize(custeio), [custeio, calculateTotalDespesas]);

  useEffect(() => {
    if (custeio?.fatura) {
      dispatch(setDIModel(custeio.fatura));
    }
  }, [custeio]);

  const history = useHistory();

  const followUpStatus = useSelector(selectFollowUpStatus);
  const despesaReceitaStatus = useSelector(selectDespesaReceitaStatus);
  const diStatus = useSelector(selectDIStatus);
  const status = useSelector(selectStatus);
  const mode = useSelector(selectMode);
  const backgroundMode = useSelector(selectBackgroundMode);

  const isPreparingAction = useMemo(() => isPreparingActionStatus(status), [status]);

  const isIdle = useMemo(() => isIdleStatus(status), [status]);

  const isFailure = useMemo(() => isFailureStatus(status), [status]);

  const isNone = useMemo(() => isNoneMode(mode), [mode]);

  const isCreate = useMemo(() => isCreateMode(mode), [mode]);

  const isConsult = useMemo(() => isConsultMode(mode), [mode]);

  const isUpdate = useMemo(() => isUpdateMode(mode), [mode]);

  const isLocked = useMemo(() => isLockedMode(mode), [mode]);

  const isBackgroundCreate = useMemo(() => isBackgroundCreateMode(backgroundMode), [backgroundMode]);

  const isBackgroundUpdate = useMemo(() => isBackgroundUpdateMode(backgroundMode), [backgroundMode]);

  const isBackgroundDelete = useMemo(() => isBackgroundDeleteMode(backgroundMode), [backgroundMode]);

  useEffect(() => {
    if (isCreate && !custeio?.despesas && custeio?.followUp?.numero) {
      dispatch(carregaDespesasAsync(custeio?.followUp?.numero));
    }
  }, [isCreate]);

  const handleChangeToPreparingAction = useCallback(() => {
    dispatch(preparingAction());
  }, []);

  const handleChangeToCreate = useCallback(() => {
    dispatch(changeToCreateMode());
  }, []);

  const handleChangeToBackgroundCreate = useCallback(() => {
    dispatch(changeToBackgroundCreateMode());
  }, []);

  const handleChangeToBackgroundDelete = useCallback(() => {
    dispatch(changeToBackgroundDeleteMode());
  }, []);

  const handleChangeToConsult = useCallback(() => {
    dispatch(changeToConsultMode());
  }, []);

  const handleChangeToUpdate = useCallback((additional = {} as any) => {
    dispatch(changeToUpdateMode());

    if (!isEmpty(additional) && isFunction(additional?.callback)) {
      additional.callback();
    }
  }, []);

  const handleResetBackgroundMode = useCallback(() => {
    dispatch(resetBackgroundMode());
  }, []);

  const handleBasicCancelUpdate = useCallback(
    (additional = {} as any) => {
      const currentId = isBackgroundCreate ? custeio?.id : params?.id;

      if (currentId) {
        fetchById(currentId.toString());
      }

      handleChangeToConsult();

      if (!isEmpty(additional) && isFunction(additional?.callback)) {
        additional.callback();
      }
    },
    [params, custeio, isBackgroundCreate, fetchById, handleChangeToConsult]
  );

  const operationOfCancelUpdateOptions = useMemo(
    () => ({
      title: t('com.muralis.qcx.aviso').toUpperCase(),
      message: t('com.muralis.qcx.mensagem.alteracoesRealizadasSeraoPerdidas'),
      endMessage: t('com.muralis.qcx.mensagem.certezaDesejaCancelar'),
    }),
    []
  );

  const [handleCancelUpdate, operationOfCancelUpdate] = useOperationConfirm(
    handleBasicCancelUpdate,
    operationOfCancelUpdateOptions,
    [handleBasicCancelUpdate, operationOfCancelUpdateOptions]
  ) as [
    (...args: any[]) => void,
    {
      confirm: () => void;
      reset: () => void;
      active: boolean;
      authorized: boolean;
      title: string;
      message: string;
      endMessage: string;
    }
  ];

  const normalize = useCallback(
    (unnormalizedData: any) => {
      const { followUp, ...data } = unnormalizedData;

      const normalizedDespesas = data?.despesas?.map((d: any) => {
        return typeof d?.id === 'string'
          ? {
              ...d,
              id: null,
              valorReal: normalizeNumeral(d.valorReal),
            }
          : {
              ...d,
              valorReal: normalizeNumeral(d.valorReal),
            };
      });

      const normalizedFollowUp = {
        ...followUp,
        referencias: followUp?.referencias?.map((r: any) => r?.referencia),
      };

      return {
        ...data,
        followUp: normalizedFollowUp,
        despesas: normalizedDespesas,
      };
    },
    [custeio]
  );

  const handleDispatchSetModel = useCallback(
    (data: any) => {
      const normalizedData = unnormalize(data);

      dispatch(setModel(normalizedData));
    },
    [unnormalize]
  );

  const create = useCallback(
    async (data: any, next: any) => {
      const executeDebounced = debounce(async () => {
        try {
          const response = await custeiosAPI.register(data);

          if (response?.status === 201) {
            const created = response?.data;

            next();
            dispatch(success());
            dispatch(setModel(created));

            if (response?.data?.observacoes) {
              dispatch(
                setSuccessFeedback({
                  message: 'O Custeio foi salvo com sucesso. Porém, ' + response?.data?.observacoes,
                })
              );
            }
          }
        } catch (error: any) {
          dispatch(setModel(data));
          dispatch(changeToBackgroundCreateMode());

          const responseErrorMessage =
            error?.response && error?.response?.data && error?.response?.data?.message
              ? error?.response?.data?.message
              : t('com.muralis.qcx.erro.erroRegistrarFollowUp');

          dispatch(failure());
          dispatch(
            setErrorFeedback({
              message: responseErrorMessage,
            })
          );
          return;
        }
      }, 500);

      dispatch(loading());
      executeDebounced();
    },
    [history]
  );

  const update = useCallback(
    async (data: any, next: any, step: any) => {
      const executeDebounced = debounce(async () => {
        try {
          const response = await custeiosAPI.save(data);

          if (response?.status === 200) {
            const handleResultWithDebounce = debounce(() => {
              if (!isCreate) {
                handleChangeToConsult();
              }

              dispatch(success());

              const saved = response?.data;

              dispatch(
                setResponse({
                  status: response.status,
                  data: saved,
                  message: t('com.muralis.qcx.mensagem.followUpSalvo', {
                    numero: data?.numero,
                  }),
                })
              );

              dispatch(setModel(saved));
              dispatch(updateOnList({ data: saved }));
            }, 500);

            handleResultWithDebounce();
          }
        } catch (error: any) {
          const responseErrorMessage =
            error?.response && error?.response?.data && error?.response?.data?.message
              ? error?.response?.data?.message
              : undefined;

          if (!responseErrorMessage) {
            dispatch(failure());
            dispatch(
              setError({
                message: t('com.muralis.qcx.erro.erroSalvarFollowUp'),
              })
            );

            return;
          }

          const fullErrorMessage = t('com.muralis.qcx.erro.erroSalvarFollowUpEspecifico', {
            mensagem: responseErrorMessage,
          });

          dispatch(failure());
          dispatch(
            setError({
              message: fullErrorMessage,
            })
          );
        }
      }, 500);

      dispatch(loading());
      executeDebounced();
    },
    [handleChangeToConsult]
  );

  const handleSubmit = useCallback(
    async (data: any, step: any, next: any) => {
      const normalizedData = normalize(data);

      if ((isUpdate && !isBackgroundCreate) || isBackgroundUpdate || step !== 0) {
        await update(normalizedData, next, step);

        const isLastStep = step === 1;
        if (isLastStep) {
          history.push('/importacao/custeios');
        }

        return;
      }

      if (isCreate || isBackgroundCreate) {
        await create(normalizedData, next);
      }
    },
    [isCreate, isUpdate, isBackgroundCreate, isBackgroundUpdate, normalize, update, create]
  );

  const actionName = useMemo(() => {
    if (isCreate || isBackgroundCreate || isNone) return t('com.muralis.qcx.acoes.novo');
    if (isConsult) return t('com.muralis.qcx.acoes.visualizar');
    return t('com.muralis.qcx.acoes.alterar');
  }, [isNone, isCreate, isConsult, isBackgroundCreate]);

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloImportacao'),
          name: t('com.muralis.qcx.importacao.label'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.importacaoCusteios'),
          name: t('com.muralis.qcx.custeios.labelPlural'),
        },
      },
      {
        text: {
          name: t('com.muralis.qcx.registro'),
        },
      },
      {
        text: {
          name: actionName,
        },
      },
    ],
    [isCreate, actionName]
  );

  const pageTitle = useMemo(
    () => (isNone || isCreate || isBackgroundCreate ? 'Novo Registro' : 'Custeios'),
    [isNone, isCreate, isBackgroundCreate, model]
  );

  useEffect(() => {
    const configureDependencies = () => {
      dispatch(clienteActions.changeControlTo(IDLE_CONTROL));
      dispatch(servicoActions.changeControlTo(IDLE_CONTROL));
      dispatch(transportadoraActions.changeControlTo(IDLE_CONTROL));
    };

    configureDependencies();

    return () => {
      dispatch(clienteActions.resetControl());
      dispatch(servicoActions.resetControl());
      dispatch(transportadoraActions.resetControl());
    };
  }, []);

  useEffect(() => {
    const handleFetchById = () => {
      if (!isEmpty(params?.id)) {
        fetchById(params?.id);

        handleChangeToConsult();
      }
    };

    handleFetchById();
  }, [params, handleChangeToConsult]);

  const refreshSelectedModel = useCallback(() => {
    if (!isEmpty(custeio) && custeio?.id) {
      fetchById(custeio?.id.toString());
    }
  }, [custeio, isUpdate, fetchById, handleChangeToConsult]);

  const handleCalcular = useCallback(async () => {
    try {
      if (id) {
        dispatch(startLoading());
        const response = await custeiosAPI.calculateById(Number(id));
        if (response?.status === 200) {
          dispatch(setModel(response?.data));
          dispatch(setSuccessFeedback({ message: 'Custeio calculado com sucesso' }));
        } else {
          dispatch(setErrorFeedback({ message: 'Erro ao calcular custeio' }));
        }
        dispatch(finishLoading());
      }
    } catch (error) {
      console.log(error);
    }
  }, [id]);

  const handleGerarPlanilha = useCallback(() => {
    try {
      if (id) {
        dispatch(startLoading());
        dispatch(generateSpreadsheetById({ id }));
      }
    } catch (error) {
      console.log(error);
    }
  }, [id, dispatch]);

  const actionBarProps: ActionsBarProps = useMemo(
    () => ({
      buttons: [
        {
          icon: FaFileExcel,
          title: 'Exportar planilha',
          onClick: handleGerarPlanilha,
          isLoading,
        },
        {
          icon: FaCalculator,
          title: t('com.muralis.qcx.acoes.calcular'),
          onClick: handleCalcular,
          disabled: di?.atributosAdicionais?.calculada === 'CALCULADA',
          isLoading,
        },
      ],
    }),
    [isLoading]
  );

  return (
    <>
      <QCXProgressBackdrop
        open={
          isLoading ||
          status === LOADING_STATUS ||
          diStatus === LOADING_STATUS ||
          despesaReceitaStatus === LOADING_STATUS ||
          followUpStatus === LOADING_STATUS
        }
      />
      <QCXRegistrationFormPageTemplate
        authInfo={authInfo}
        breadcrumbs={breadcrumbs}
        handleCancelUpdate={handleCancelUpdate}
        handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
        handleChangeToBackgroundDelete={handleChangeToBackgroundDelete}
        handleChangeToConsult={handleChangeToConsult}
        handleChangeToCreate={handleChangeToCreate}
        handleChangeToPreparingAction={handleChangeToPreparingAction}
        handleChangeToUpdate={handleChangeToUpdate}
        handleResetBackgroundMode={handleResetBackgroundMode}
        isBackgroundCreate={isBackgroundCreate}
        isBackgroundDelete={isBackgroundDelete}
        isConsult={isConsult}
        isCreate={isCreate}
        isFailure={isFailure}
        isIdle={isIdle}
        isLocked={isLocked}
        isPreparingAction={isPreparingAction}
        isUpdate={isUpdate}
        pageIcon={undefined}
        pageTitle={pageTitle}
        actionBarProps={actionBarProps}
      >
        {(formProps: any) => (
          <>
            <QCXCusteiosWizardFinalForm
              model={model}
              handleChangeModel={handleDispatchSetModel}
              handleSubmit={handleSubmit}
              refreshSelectedModel={refreshSelectedModel}
              authInfo={authInfo}
              requiredRoles={[]}
              isLoading={isLoading}
              {...formProps}
            >
              <QCXConfirmDialog
                open={operationOfCancelUpdate?.active}
                title={operationOfCancelUpdate?.title}
                content={operationOfCancelUpdate?.message}
                endContent={operationOfCancelUpdate?.endMessage}
                onConfirm={operationOfCancelUpdate.confirm}
                onClose={operationOfCancelUpdate?.reset}
                buttonGroupOptions={{
                  confirm: {
                    description: t('com.muralis.qcx.expressao.sim'),
                    variant: '',
                    color: '',
                  },
                  cancel: {
                    description: t('com.muralis.qcx.expressao.nao'),
                    variant: '',
                    color: '',
                  },
                }}
                renderMiddleMessage={undefined}
                onSecondaryConfirm={undefined}
              />
            </QCXCusteiosWizardFinalForm>
            <QCXConfirmDialog
              open={operationOfCancelUpdate?.active}
              title={operationOfCancelUpdate?.title}
              content={operationOfCancelUpdate?.message}
              endContent={operationOfCancelUpdate?.endMessage}
              onConfirm={operationOfCancelUpdate.confirm}
              onClose={operationOfCancelUpdate?.reset}
              buttonGroupOptions={{
                confirm: {
                  description: t('com.muralis.qcx.expressao.sim'),
                  variant: '',
                  color: '',
                },
                cancel: {
                  description: t('com.muralis.qcx.expressao.nao'),
                  variant: '',
                  color: '',
                },
              }}
              renderMiddleMessage={undefined}
              onSecondaryConfirm={undefined}
            />
          </>
        )}
      </QCXRegistrationFormPageTemplate>
    </>
  );
}
