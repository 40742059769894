import React, { useEffect, useMemo, useState } from 'react';
import { OnChange } from 'react-final-form-listeners';
import { useFormState, useForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { createFilterOptions } from '@material-ui/lab/useAutocomplete';


import QCXFinalNumericDecimalField from '../../../shared-components/final-numeric-decimal-field/QCXFinalNumericDecimalField'; // float


export default function QCXAtributoNumeroReal({
    atributo, 
    avaliaCondicionantesEvent, 
    isCondicionado, 
    isConsult, 
    isComposto = false,
    codAtributoPai, 
    ...restProps
}) {

    const [label, setLabel] = useState("")
    const [name,  setName] = useState("")
    const [obrigatorio, setObrigatorio] = useState(false);
    const [codigo, setCodigo] = useState("");
    const [listValores, setListValores] = useState([]);
    const [defaultValue, setDefaultValue] = useState('');
    const [tamanhoMaximo, setTamanhoMaximo] = useState(null);
    const path = `atributosPreenchidos`
    const form = useForm();

    const verificaCondicionante = (value) => {
        const condicionados =  _.get(atributo, "detalhesAtributos.condicionados")
        if(condicionados && _.isArray(condicionados) && condicionados.length && _.isFunction(avaliaCondicionantesEvent)) {
            avaliaCondicionantesEvent(value, codigo)
        }
    }

    useEffect(() => {
        
        if(atributo && atributo.detalhesAtributos) {
            const detalhes = atributo.detalhesAtributos;
            const condicionados =  _.get(atributo, "detalhesAtributos.condicionados")

            setLabel(detalhes.nomeApresentacao)
            setCodigo(detalhes.codigo)
            setName(`${path}.atributos.${detalhes.codigo}.valor`)
            setObrigatorio(detalhes.obrigatorio)
            setTamanhoMaximo(detalhes.tamanhoMaximo)

            if(isComposto && codAtributoPai) {
                setName(`${path}.atributosCompostos.${codAtributoPai}.valores.${detalhes.codigo}.valor`)
            }

        }

        return null;
    }, [])

    if(!name) {
        return null
    }
    
    return (
        <>
            <QCXFinalNumericDecimalField
                id={codigo}
                key={codigo}
                name={name}
                label={label}
                disabled={isConsult}
                scale={7}
                required={obrigatorio}
                {...restProps}
            />
            <OnChange name={name}>
                {(value, previous) => verificaCondicionante(value)}
            </OnChange>
        </>

    )
}
