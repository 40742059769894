import { Grid, makeStyles } from '@material-ui/core';
import { isFunction } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { ncmSubitemActions } from '../../features/ncm-subitem/ncmSubitemSlice';
import QCXRenderConditionalComponent from '../catalogo-produtos/QCXRenderConditionalComponent';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';

const useStyles = makeStyles((theme) => ({
  gridFields: {
    paddingBottom: '16px',
  },
  subtitle: {
    paddingTop: '16px !important',
  },
  destaqueNcmFieldLeft: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '16px',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '16px',
    },
  },
  // FIXME: Fake field
  hiddenFakeFields: {
    width: '0px',
    height: '0px',
    visibility: 'hidden',
    zIndex: -9999,
    position: 'absolute',
  },
}));

export default function QCXFaturaAtributosNcmDinamico({
  modes,
  codObjetivo = 7, // 7 default produtos, 5 -> duimp
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const dispatch = useDispatch();
  const { values } = useFormState();
  const [ncmSubItem, setNcmSubItem] = useState({});
  const { isConsult, isSubNone, isSubConsult } = modes;
  const [atributos, setAtributos] = useState([]);

  const ncmList = useSelector(ncmSubitemActions.selectNcmSubitens);
  const ncmSubItemModel = useSelector(ncmSubitemActions.selectNcmSubitem);
  const ncm = useMemo(() => values?.ncm, [values]);

  useEffect(() => {
    if (ncm && ncm.id) {
      dispatch(ncmSubitemActions.fetchByIdAsync(ncm.id));
    }
  }, [ncm]);

  useEffect(() => {
    if (ncmSubItemModel && ncmSubItemModel.listaAtributosMetadados) {
      const atributosObjetivo = ncmSubItemModel.listaAtributosMetadados.filter((atributoFilter) => {
        let objetivos = null;
        if (!atributoFilter) return false;
        if (atributoFilter.objetivos) {
          objetivos = atributoFilter.objetivos;
        } else if (atributoFilter.detalhesAtributos && atributoFilter.detalhesAtributos.objetivos) {
          objetivos = atributoFilter.detalhesAtributos.objetivos;
        }

        if (!objetivos || (objetivos && !Array.isArray(objetivos))) return false;

        const objDuimp = objetivos.find((obj) => obj && obj.codigo === `${codObjetivo}`);

        if (objDuimp) return true;

        return false;
      });

      if (atributosObjetivo && Array.isArray(atributosObjetivo)) {
        setAtributos([...atributosObjetivo]);
      }
    } else {
      setAtributos([]);
    }
  }, [ncmSubItemModel]);

  return (
    <Grid container>
      <Grid item className={classes.subtitle} xs={12}>
        <QCXFormSubtitle title="ATRIBUTOS NCM" />
      </Grid>

      <Grid container spacing={2} style={{ marginTop: '1rem' }}>
        {atributos != null && Array.isArray(atributos) ? (
          atributos.map((atributo) => <QCXRenderConditionalComponent atributo={atributo} disabled={isConsult} />)
        ) : (
          <Grid item>Nenhum atributo de mercadoria encontrado para este NCM !!</Grid>
        )}
      </Grid>
    </Grid>
  );
}
