// MapaProcessosPage.tsx

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { Button, Grid } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { setErrorFeedback, setSuccessFeedback } from '../../../../features/feedback/feedbackSlice';
import { generateMapaProcessosReport } from './mapaProcessosPage.helpers';
import { KEYCLOAK_TOKEN_TIMEOUT } from '../../../../App';
import { useUnidadeNegocioGuard } from '../../../common/hooks/useUnidadeNegocioGuard';

import {
  selectUnidadeSelecionada,
  selectUnidadesDeNegocioAssociadas,
} from '../../../../features/usuario-logado/usuarioLogadoSlice';
import { Field, Form } from 'react-final-form';
import { useListClientes } from '../../../common/hooks/useListClientes';
import MultiSelectFormInput from '../../../common/components/forms/MultiSelectFormInput';
import QCXFinalDatePickerField from '../../../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import PageLayout from '../../../common/layouts/pageLayout';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const useStyles = makeStyles({
  inputLabel: {
    backgroundColor: 'white',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    gap: '10px',
  },
  input: {
    height: 40,
  },
  button: {
    borderRadius: '4px',
  },
});

interface BusinessUnitOption {
  id: number;
  value: string;
}

interface ClienteOption {
  id: number;
  value: string;
}

interface FormValues {
  unidadesDeNegocio: BusinessUnitOption[];
  clientes: ClienteOption[];
  dataInicio: string;
  dataFim: string;
}

const MapaProcessosPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  const { token } = keycloak;
  keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);

  const selectedUnit = useSelector(selectUnidadeSelecionada);
  const businessUnits = useSelector(selectUnidadesDeNegocioAssociadas);

  const [clientes] = useListClientes();

  useUnidadeNegocioGuard();

  useEffect(() => {
    keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
  }, [keycloak]);

  const businessUnitsOptions: BusinessUnitOption[] = businessUnits.map((unit: { id: any; pessoa: { nomeResumido: any; nome: any; }; }) => ({
    id: unit.id,
    value: unit.pessoa.nomeResumido ?? unit.pessoa.nome,
  }));

  const clientesOptions: ClienteOption[] = clientes
    .filter((client) => client.id !== undefined)
    .map((client) => ({
      id: client.id!,
      value: `(${client.pessoa!.cnpj}) ${client.pessoa!.nome}`,
    }));

  const handleSubmit = async (values: FormValues) => {
    keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);

    const { unidadesDeNegocio, clientes, dataInicio, dataFim } = values;

    // Validate dates
    if (!dataInicio || !dataFim || new Date(dataInicio) > new Date(dataFim)) {
      dispatch(
        setErrorFeedback({
          message: 'Preencha as datas de início e fim corretamente.',
        })
      );
      return;
    }

    if (token && selectedUnit && unidadesDeNegocio && unidadesDeNegocio.length > 0) {
      try {
        const unidadesDeNegocioIds = unidadesDeNegocio.map((un) => un.id.toString());
        const clientesIds = clientes ? clientes.map((client) => client.id.toString()) : [];

        const error = await generateMapaProcessosReport(
          token,
          selectedUnit,
          unidadesDeNegocioIds,
          clientesIds,
          dataInicio,
          dataFim
        );
        if (error) {
          dispatch(setErrorFeedback({ message: error }));
        } else {
          dispatch(setSuccessFeedback({ message: 'Relatório gerado com sucesso!' }));
          // You can reset the form or navigate elsewhere if needed
        }
      } catch (error) {
        dispatch(setErrorFeedback({ message: 'Erro ao gerar o relatório.' }));
      }
    } else {
      dispatch(setErrorFeedback({ message: 'Por favor, preencha todos os campos obrigatórios.' }));
    }
  };

  return (
    <PageLayout title={'Mapa de Processos'} icon={<InsertDriveFileIcon color={'secondary'} />}>
      <Form<FormValues>
        onSubmit={handleSubmit}
        render={({ handleSubmit, submitting, hasValidationErrors }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {/* Unidade de Negócio multi-select */}
              <Grid item xs={12}>
                <Field<BusinessUnitOption[]>
                  name="unidadesDeNegocio"
                  render={({ input, meta }) => (
                    <MultiSelectFormInput
                      name={input.name}
                      label="Unidade de Negócio"
                      options={businessUnitsOptions}
                      disablePortal={false}
                      onChangeCallback={(selected) => input.onChange(selected)}
                    />
                  )}
                />
              </Grid>
              {/* Clientes multi-select */}
              <Grid item xs={12}>
                <Field<ClienteOption[]>
                  name="clientes"
                  render={({ input }) => (
                    <MultiSelectFormInput
                      name={input.name}
                      label="Clientes"
                      options={clientesOptions}
                      disablePortal={false}
                      onChangeCallback={(selected) => input.onChange(selected)}
                    />
                  )}
                />
              </Grid>
              {/* Date fields */}
              <Grid item xs={6} style={{ marginBottom: '25px' }}>
                <Field<string>
                  name="dataInicio"
                  render={({ input, meta }) => (
                    <QCXFinalDatePickerField
                      id="mapa-processos-data-inicio"
                      name={input.name}
                      label="Data de Início"
                      required={true}
                      validate={undefined}
                      format="DD/MM/YYYY"
                      placeholder="DD/MM/YYYY"
                      onChange={(date: { toISOString: () => string; }) => {
                        input.onChange(date.toISOString().split('T')[0]);
                      }}
                      error={meta.error && meta.touched}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} style={{ marginBottom: '25px' }}>
                <Field<string>
                  name="dataFim"
                  render={({ input, meta }) => (
                    <QCXFinalDatePickerField
                      id="mapa-processos-data-fim"
                      name={input.name}
                      label="Data de Fim"
                      validate={undefined}
                      required={true}
                      format="DD/MM/YYYY"
                      placeholder="DD/MM/YYYY"
                      onChange={(date: { toISOString: () => string; }) => {
                        input.onChange(date.toISOString().split('T')[0]);
                      }}
                      error={meta.error && meta.touched}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={submitting || hasValidationErrors}
                >
                  Gerar Relatório
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </PageLayout>
  );
};

export default MapaProcessosPage;
