
import {
  Typography, Grid, Paper, TableContainer, Table,
  TableRow, TableCell, TableBody, TableHead
} from '@material-ui/core';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';


export interface IDiagnostico {
  diagnostico: DiagnosticoItem[];
}

export interface DiagnosticoItem {
  retificacao: string;
  protocolo: string;
  numeroIdentificacao: string;
  motivoTransmissao: string;
  textoStatus: string;
  dataProcessamento: string;
  numeroTransmissao: string;
  erros: DiagnosticoItemErro | null;
  status: string;
  numeroDI: string | null;
}

interface DiagnosticoItemErro {
  adicao: string;
  mensagem: string;
  status: string;
}

const useStyles = makeStyles({
  chave: {
    display: 'flex',
    fontWeight: 'bold',
    fontSize: '1rem',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
  },
  valor: {
    fontSize: '1rem',
    marginLeft: '0.5rem',
    fontWeight: 'normal',
    color: '#212121',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    flex: 1,
  },
  tableHeaderCell: {
    fontWeight: 'bold',
    color: '#212121',
  },
  tableCell: {
    borderBottom: '1px solid #e0e0e0',
  },
  mensagem: {
    wordWrap: 'break-word',
    whiteSpace: 'normal',
  },
  titulo: {
    fontSize: '1.rem',
    fontWeight: 'bold',
    color: 'secondary',
  },
});

export interface IDiagnostico {
  diagnostico: DiagnosticoItem[];
}

export interface DiagnosticoItem {
  retificacao: string;
  protocolo: string;
  numeroIdentificacao: string;
  motivoTransmissao: string;
  textoStatus: string;
  dataProcessamento: string;
  numeroTransmissao: string;
  erros: DiagnosticoItemErro | null;
  status: string;
  numeroDI: string | null;
}

interface DiagnosticoItemErro {
  adicao: string;
  mensagem: string;
  status: string;
}

export const TransmissaoRenderDadosDi = ({ diagnostico } : { diagnostico: DiagnosticoItem[] }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const dataDeProcessamento = t('com.muralis.qcx.extracaoDiagnostico.dataDeProcessamento');
  const numeroTransmissao = t('com.muralis.qcx.numeroTransmissao');
  const numeroIdentificacao = t('com.muralis.qcx.extracaoDiagnostico.numeroIdentificacao');
  const status = t('com.muralis.qcx.status');
  const retificacao = t('com.muralis.qcx.extracaoDiagnostico.retificacao');
  const protocolo = t('com.muralis.qcx.extracaoDiagnostico.protocolo');
  const motivoTransmissao = t('com.muralis.qcx.extracaoDiagnostico.motivoTransmissao');
  const impedimento = t('com.muralis.qcx.extracaoDiagnostico.impedimento');
  const adicao = t('com.muralis.qcx.adicao.label');
  const mensagemDeErro = t('com.muralis.qcx.extracaoDiagnostico.mensagemDeErro');
  const numeroDI = t('com.muralis.qcx.numeroDI');

  return (
    <Paper style={{ padding: '20px' }}>
      {diagnostico.map((item, index) => (
        <Grid container key={index} spacing={3}>
          <Grid item xs={6}>
            <Typography variant="h6" color='primary' className={classes.chave}>
              {dataDeProcessamento + ": "} <span className={classes.valor}>{item.dataProcessamento.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3")}</span>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" color='primary' className={classes.chave}>
              {numeroTransmissao + ": "} <span className={classes.valor}>{item.numeroTransmissao}</span>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" color='primary' className={classes.chave}>
              {numeroIdentificacao + ": "} <span className={classes.valor}>{item.numeroIdentificacao}</span>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" color='primary' className={classes.chave}>
              {retificacao + ": "} <span className={classes.valor}>{item.retificacao}</span>
            </Typography>
          </Grid>


          <Grid item xs={6}>
            <Typography variant="h6" color='primary' className={classes.chave}>
              {protocolo + ": "} <span className={classes.valor}>{item.protocolo}</span>
            </Typography>
          </Grid>

          {item.numeroDI && (
            <Grid item xs={6}>
              <Typography variant="h6" color='primary' className={classes.chave}>
                {numeroDI + ": "} <span className={classes.valor}>{item.numeroDI}</span>
              </Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <Typography variant="h6" color='primary' className={classes.chave}>
              {motivoTransmissao + ": "} <span className={classes.valor}>{item.motivoTransmissao}</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" color='primary' className={classes.chave}>
              {status + ": "} <span className={classes.valor}>{item.status} ( {item.textoStatus} )</span>
            </Typography>
          </Grid>

          {Array.isArray(item.erros) && item.erros.length > 0 && (
            <TableContainer>
              <Typography color='secondary' variant='h6' className={classes.titulo} style={{ textAlign: 'center', margin: '1rem auto' }}>
                Relatório de Impedimento
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeaderCell}>{impedimento}</TableCell>
                    <TableCell className={classes.tableHeaderCell}>{adicao}</TableCell>
                    <TableCell className={classes.tableHeaderCell}>{mensagemDeErro}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item.erros.map((erro, index) => (
                    <TableRow key={index}>
                      <TableCell>{erro.status === 'I' ? <ErrorOutlineIcon color="error" /> : erro.status}</TableCell>
                      <TableCell>{erro.adicao}</TableCell>
                      <TableCell className={classes.mensagem}>{erro.mensagem}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      ))}
    </Paper>
  );
};
