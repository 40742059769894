import { Box, Grid, FormControl, Typography, Button } from '@mui/material';
import { ProcessosChart } from './ProcessosChart';
import { Canais } from './Canais';
import BasicLayout from '../../../common/layouts/basicLayout';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { useState, useEffect, SyntheticEvent } from 'react';
import ColumnSelector from './ColumnSelector';
import SelectStyled from "../../../common/components/input/selectStyled";
import QCXSelectUnidadeLocalRFBAutocomplete from '../../../../shared-components/select-unidade-local-rfb/QCXSelectUnidadeLocalRFBAutocomplete';
import QCXFinalDatePickerField from '../../../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXFinalSelectTipoProdutoAutocomplete from '../../../../shared-components/select-tipo-produto/QCXFinalSelectTipoProdutoAutocomplete';
import { useDispatch, useSelector } from "react-redux";
import { useListClientes } from "../../../common/hooks/useListClientes";
import { fetchAllAnalistas } from "../../../../features/usuario/usuarioAPI";
import { loading, success } from '../../../../features/danfe/danfeSlice';
import {
  selectUnidadesDeNegocioAssociadas,
  selectUnidadeSelecionada,
} from '../../../../features/usuario-logado/usuarioLogadoSlice';
import { useUnidadeNegocioGuard } from "../../../common/hooks/useUnidadeNegocioGuard";
import { Form, Field } from 'react-final-form';
import moment from 'moment';
import { required as requiredValidator } from '../../../../utils/validators/field/validator';
import MultiSelectStyled, { SimpleMultiSelectOption } from '../../../common/components/input/multiSelectStyled';
import { fetchColumnValues, downloadAllProcessesSheet } from './relatorioDashboardPage.helpers';
import { KEYCLOAK_TOKEN_TIMEOUT } from '../../../../App';
import { useKeycloak } from '@react-keycloak/web';
import { FileDownload as FileDownloadIcon, Description as ExcelIcon } from '@mui/icons-material';
import { setErrorFeedback } from '../../../../features/feedback/feedbackSlice';

interface Column {
  name: string;
  checked: boolean;
}

interface ControlledValue {
  id: string | number;
  value: string;
}

const RelatorioDashboardPage = () => {
  const initialData = Array(15).fill(0);
  const canalInitialData = Array(4).fill(0);
  const canaisNomes = ['Verde', 'Amarelo', 'Vermelho', 'Cinza'];
  const [columns, setColumns] = useState<Column[]>([
    { name: 'Abertos', checked: true },
    { name: 'Pend. de Chegada', checked: true },
    { name: 'Chegados', checked: true },
    { name: 'Digitados', checked: true },
    { name: 'Pend. de Numerário', checked: true },
    { name: 'Numerário Solicitado', checked: true },
    { name: 'Pend. Receb. Numerário', checked: true },
    { name: 'Pend. de Reg. de DI', checked: true },
    { name: 'com DI Registrada', checked: true },
    { name: 'Pend. de Parametrização', checked: true },
    { name: 'Pend. de Desembaraço', checked: true },
    { name: 'Desembaraçados', checked: true },
    { name: 'Aguardando Transporte', checked: true },
    { name: 'Enviados p Faturamento', checked: true },
    { name: 'Faturados', checked: true }
  ]);

  useUnidadeNegocioGuard();
  const [data, setData] = useState<number[]>(initialData);
  const [canalData, setCanalData] = useState<number[]>(canalInitialData);
  const { keycloak } = useKeycloak();
  const { token } = keycloak;
  keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
  const selectedUnit = useSelector(selectUnidadeSelecionada);
  const [dataInicial, setDataInicial] = useState<string>('');
  const [dataFinal, setDataFinal] = useState<string>(moment().add(5, 'days').format('YYYY-MM-DD'));
  const list = useSelector(selectUnidadesDeNegocioAssociadas);
  const [selectedBusiness, setSelectedBusiness] = useState<SimpleMultiSelectOption[]>([]);
  const [businessError, setBusinessError] = useState(false);
  const [clientes] = useListClientes();
  const [selectedClients, setSelectedClients] = useState<ControlledValue[]>([]);
  const [analyst, setAnalyst] = useState<ControlledValue[]>([]);
  const [analystList, setAnalystList] = useState<ControlledValue[]>([]);
  const dispatch = useDispatch();
  const [showWarning, setShowWarning] = useState(false);
  const [buscado, setBuscado] = useState(false);

  useEffect(() => {
    getAnalistas();
  }, []);

  async function getAnalistas() {
    let a = (await fetchAllAnalistas()).data;
    let list = a.map((elem: { id: any; nome: any; email: any; }) => ({
      id: elem.id,
      value: `${elem.nome} - ${elem.email}`
    }));
    setAnalystList(list);
  }

  const handleClientsChange = (event: SyntheticEvent<Element, Event>, value: { id: string | number | undefined; value: string | undefined }[] | null) => {
    const newSelectedClients: ControlledValue[] = [];
    value?.forEach((value) => {
      const selectedClient = clientes.find((cliente) => cliente.id === value.id);
      if (selectedClient && selectedClient.id && !selectedClients.some((client) => client.id === selectedClient.id)) {
        newSelectedClients.push({
          id: selectedClient.id.toString(),
          value: `(${selectedClient.pessoa!.cnpj}) ${selectedClient.pessoa!.nome}`
        });
      }
    });
    setSelectedClients(newSelectedClients);
  };

  const handleChangeAnalyst = (event: SyntheticEvent<Element, Event>, value: { id: string | number | undefined; value: string | undefined }[] | null) => {
    const newList: ControlledValue[] = [];
    value?.forEach((v) => {
      const temp = analystList.find((elem) => elem.id === v.id);
      if (temp && !newList.includes(temp)) {
        newList.push(temp);
      }
    });
    setAnalyst(newList);
  };

  const handleColumnsChange = (updatedColumns: Column[]) => {
    setColumns(updatedColumns);
  };

  const handleDownload = async (values: any) => {
    if(!buscado){
      setShowWarning(true);
      return;
    }
    keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);

    if (selectedBusiness.length === 0) {
      setBusinessError(true);
      return;
    } else {
      setBusinessError(false);
    }
    if (token && selectedUnit) {
      try {
        const dataInicio = dataInicial;
        const dataFim = dataFinal;

        const activeColumns = columns.filter(column => column.checked).map(column => column.name);

        await downloadAllProcessesSheet(
          token,
          selectedUnit,
          selectedBusiness.map(b => b.id.toString()),
          selectedClients.map(c => c.id.toString()),
          analyst.map(a => a.id.toString()),
          values.canais ? values.canais.id : '',
          values.urfDespacho ? values.urfDespacho.id : null,
          values.produto || '',
          dataInicio,
          dataFim,
          activeColumns
        );
        dispatch(success());
      } catch (error) {
        dispatch(setErrorFeedback({
          message: 'Erro ao baixar o arquivo.'
        }));
      }
    }
  };

  const handleSubmit = async (values: any) => {
    keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);

    if (selectedBusiness.length === 0) {
      setBusinessError(true);
      return;
    } else {
      setBusinessError(false);
    }

    const dataInicio = values.startDate ?
      new Date(values.startDate).getFullYear() + '-' + (new Date(values.startDate).getMonth() + 1) + '-' + new Date(values.startDate).getDate() : '';
    const dataFim = values.endDate ?
      new Date(values.endDate).getFullYear() + '-' + (new Date(values.endDate).getMonth() + 1) + '-' + new Date(values.endDate).getDate() : '';
    const selectedBusinessIds = selectedBusiness.map(b => b.id.toString());
    const selectedClientsIds = selectedClients.map(c => c.id.toString());
    const analystValues = analyst.map(a => a.value.toString());
    const canaisValue = values.canais ? values.canais.id : '';
    const urfDespachoId = values.urfDespacho ? values.urfDespacho.id : null;
    const produtoValue = values.produto || '';
    setDataInicial(dataInicio);
    setDataFinal(dataFim);
    setBuscado(true);
    setShowWarning(false);

    if (token && selectedUnit) {
      try {
        dispatch(loading());
        const responseData = await fetchColumnValues(
          token,
          selectedUnit,
          selectedBusinessIds,
          selectedClientsIds,
          analystValues,
          canaisValue,
          urfDespachoId,
          produtoValue,
          dataInicio,
          dataFim
        );

        setData([
          responseData.abertos,
          responseData.pendentesChegada,
          responseData.chegados,
          responseData.comCalculo,
          responseData.pendenteSolicitacaoNumerario,
          responseData.numerarioSolicitado,
          responseData.recebimentoNumerario,
          responseData.pendenteRegistro,
          responseData.registrado,
          responseData.canalLiberacaoNull,
          responseData.desembaracoPendente,
          responseData.desembaracoRealizado,
          responseData.entregaMercadoria,
          responseData.enviadoFaturamento,
          responseData.dataFaturamento,
        ]);
        setCanalData([
          responseData.canalVerde,
          responseData.canalAmarelo,
          responseData.canalVermelho,
          responseData.canalCinza
        ]);
        dispatch(success());
        keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
      } catch (error) {
        console.error('Erro ao obter os dados do dashboard:', error);
      }
    }
  };

  const selectedColumns = columns.filter(column => column.checked);
  const selectedData = selectedColumns.map(column => data[columns.findIndex(c => c.name === column.name)]);
  const selectedCategories = selectedColumns.map(column => column.name);

  return (
    <BasicLayout title={'Relatórios - Dashboard'} icon={<AssessmentIcon color={'secondary'} />}>
      <Grid container spacing={3}>
        <Grid item lg={2.5} xs={12}>
          <Form
            onSubmit={handleSubmit}
            initialValues={{
              urfDespacho: '',
              startDate: moment().subtract(5, 'days').format('YYYY-MM-DD'),
              endDate: moment().add(5, 'days').format('YYYY-MM-DD')
            }}
            render={({ handleSubmit, values }) => (
              <Box component="form" onSubmit={handleSubmit} borderColor="grey.300" sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'left',
                marginTop: 1,
                marginLeft: 2,
                flexDirection: 'column'
              }}>
                <ColumnSelector columns={columns} onChange={handleColumnsChange} />
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    borderRadius: 2,
                    marginTop: 1,
                  }}
                  onClick={() => handleDownload(values)}
                >
                  <ExcelIcon />
                  <FileDownloadIcon sx={{ ml: 0.5 }} />
                </Button>
                {showWarning && (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ mt: 1 }}
                  >
                    Por favor, realize a busca antes de fazer o download.
                  </Typography>
                )}
              </Box>
            )}
          />
        </Grid>
        <Grid item lg={9.5} xs={12}>
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: 2 }}>
              <Box sx={{ flex: 2 }}>
                <Form
                  onSubmit={handleSubmit}
                  initialValues={{
                    urfDespacho: '',
                    startDate: moment().subtract(5, 'days').format('YYYY-MM-DD'),
                    endDate: moment().add(5, 'days').format('YYYY-MM-DD')
                  }}
                  render={({ handleSubmit, values }) => (
                    <Box component="form" onSubmit={handleSubmit} mt={2} p={2} border={1} borderRadius={4} borderColor="grey.300">
                      <Typography variant="h6">Filtros</Typography>
                      <Grid container spacing={2}>
                        <Grid item sm={4} style={{ margin: '5px 0 0 0' }}>
                          <FormControl fullWidth error={businessError}>
                            <MultiSelectStyled
                              controlledValues={selectedBusiness}
                              options={list.map((business: { id: any; pessoa: { nomeResumido: any; }; }) => ({
                                id: business.id,
                                value: business.pessoa.nomeResumido
                              }))}
                              onChangeAction={(event, value) => {
                                setSelectedBusiness(value || []);
                                values.business = value;
                              }}
                              label={"Unidade de Negócio"}
                            ></MultiSelectStyled>
                            {businessError && (
                              <Typography variant="body2" color="error">
                                Este campo é obrigatório
                              </Typography>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item sm={4} style={{ margin: '5px 0 0 0' }}>
                          <FormControl fullWidth>
                            <MultiSelectStyled
                              options={clientes.map((client) => ({
                                id: client.id ?? '',
                                value: `(${client.pessoa!.cnpj}) ${client.pessoa!.nome}`
                              }))}
                              onChangeAction={(event, value) => {
                                handleClientsChange(event, value);
                                values.clients = value;
                              }}
                              label={"Clientes"}
                            ></MultiSelectStyled>
                          </FormControl>
                        </Grid>
                        <Grid item sm={4} style={{ margin: '5px 0 5px 0' }}>
                          <MultiSelectStyled
                            key={"AnalystSelect"}
                            options={analystList.map((analyst) => ({
                              id: analyst.id,
                              value: analyst.value
                            }))}
                            onChangeAction={(event, value) => {
                              handleChangeAnalyst(event, value);
                              values.analyst = value;
                            }}
                            label={"Analista"}
                          ></MultiSelectStyled>
                        </Grid>
                        <Grid item sm={4} style={{ margin: '5px 0 5px 0' }}>
                          <SelectStyled
                            key={"CanaisSelect"}
                            options={canaisNomes.map((canal) => ({
                              id: canal,
                              value: canal
                            }))}
                            onChangeAction={(event, value) => {
                              values.canais = value;
                            }}
                            label={"Canal"}
                          ></SelectStyled>
                        </Grid>
                        <Grid item sm={4} style={{ margin: '5px 0 0 0' }}>
                          <Field
                            name="urfDespacho.id"
                            render={({ input }) => (
                              <QCXSelectUnidadeLocalRFBAutocomplete
                                id="select-field-urf-despacho"
                                key="select-field-urf-despacho"
                                name={input.name}
                                label={"URF de Despacho"}
                                initialValues={values}
                                onChange={(_event: any, value: ControlledValue | null) => {
                                  input.onChange(value?.id);
                                  values.urfDespacho = value;
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item sm={4} style={{ margin: '5px 0 5px 0' }}>
                          <QCXFinalSelectTipoProdutoAutocomplete
                            id="select-field-tipo-produto"
                            key="select-field-tipo-produto"
                            name="produto"
                            label={"Produto"}
                            initialValues={values}
                            fieldProps={{ validate: undefined }}
                            disabled={false}
                          />
                        </Grid>
                        <Grid item sm={4} style={{ margin: '0px 0 0 0' }}>
                          <Field
                            name="startDate"
                            render={({ input }) => (
                              <QCXFinalDatePickerField
                                id="date-picker-start-date"
                                key="date-picker-start-date"
                                name={input.name}
                                label={"Data de Início"}
                                required={false}
                                validate={requiredValidator}
                                format="DD/MM/YYYY"
                                placeholder="DD/MM/YYYY"
                                disablePast={false}
                                disableFuture={false}
                                minDate={undefined}
                                onChange={(date: any) => {
                                  setBuscado(false);
                                  input.onChange(date);
                                  values.startDate = date;
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item sm={4} style={{ margin: '0px 0 0 0' }}>
                          <Field
                            name="endDate"
                            render={({ input }) => (
                              <QCXFinalDatePickerField
                                id="date-picker-end-date"
                                key="date-picker-end-date"
                                name={input.name}
                                label={"Data de Fim"}
                                required={false}
                                validate={requiredValidator}
                                format="DD/MM/YYYY"
                                placeholder="DD/MM/YYYY"
                                disablePast={false}
                                disableFuture={false}
                                minDate={undefined}
                                onChange={(date: any) => {
                                  setBuscado(false);
                                  input.onChange(date);
                                  values.endDate = date;
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item sm={4} style={{ display: 'flex', alignItems: 'flex-end', margin: '0px 0 0 0' }}>
                          <Button type="submit" variant="contained" color="secondary" fullWidth>
                            Buscar
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                />
              </Box>
              <Box sx={{ flex: 1, marginLeft: 2, marginRight: 2, marginTop: 2 }}>
                <Canais
                  chartSeries={canalData}
                  labels={['Verde', 'Amarelo', 'Vermelho', 'Cinza']}
                  sx={{ height: '100%' }}
                />
              </Box>
            </Box>
            <Box sx={{ flexBasis: '90%', flexGrow: 1, marginTop: 0, marginRight: 2, border: 1, borderRadius: 6 }}>
              <ProcessosChart
                chartSeries={[{ name: 'Processos', data: selectedData }]}
                categories={selectedCategories}
                sx={{ height: '100%' }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default RelatorioDashboardPage;
