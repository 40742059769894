/* eslint-disable indent */
/* eslint-disable max-len */
import { Grid } from '@material-ui/core';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setPreviousForm } from '../../features/application-state/applicationStateSlice';
import useGetApplicationState from '../../features/application-state/useGetApplicationState';
import { loading, success } from '../../features/declaracao-importacao/declaracaoImportacaoSlice';
import {
  UNIDADE_DE_MEDIDA_PECA,
  UNIDADE_DE_MEDIDA_QUILOGRAMA_LIQUIDO,
  UNIDADE_DE_MEDIDA_UNIDADE,
} from '../../features/unidade-medida/unidadeDeMedidaUtils';
import QCXWarningAlert from '../../shared-components/alert/QCXWarningAlert';
import QCXFinalAlternativeCurrencyField from '../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXFinalDatePickerField from '../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXFinalNumericDecimalField from '../../shared-components/final-numeric-decimal-field/QCXFinalNumericDecimalField';
import QCXFinalNumericIntegerField from '../../shared-components/final-numeric-integer-field/QCXFinalNumericIntegerField';
import QCXFinalOnblurMaskedField from '../../shared-components/final-onblur-masked-field/QCXFinalOnblurMaskedField';
import QCXFinalPercentualField from '../../shared-components/final-percentual-field/QCXFinalPercentualField';
import QCXFinalMultilineTextField from '../../shared-components/final-text-field/QCXFinalMultilineTextField';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXSelectProdutoAutocomplete from '../../shared-components/select-catalogo-produto/QCXSelectProdutoAutocomplete';
import QCXSelectFormaCalculoAutocomplete from '../../shared-components/select-forma-calculo/QCXSelectFormaCalculoAutocomplete';
import { isRelacaoFornecedorFabricanteExportador } from '../../shared-components/select-relacao-fornecedor/relacaoFornecedorUtils';
import QCXSelectUnidadeMedidaAutocomplete from '../../shared-components/select-unidade-medida/QCXSelectUnidadeMedidaAutocomplete';
import { formatLINumber } from '../../utils/hooks/form/field/formatters';
import { numberOnly } from '../../utils/hooks/form/field/parsers';
import { required as requiredValidator } from '../../utils/validators/field/validator';
import QCXNcmNaladiFormGroup from '../ncm-naladi-form-group/QCXNcmNaladiFormGroup';
import QCXRelacaoFornecedorFieldGroup from '../relacao-fornecedor/QCXRelacaoFornecedorFieldGroup';
import QCXFinalAlternativeCurrencyFieldRender from './QCXFinalAlternativeCurrencyFieldRender';
import QCXFinalNumericDecimalFieldRender from './QCXFinalNumericDecimalFieldRender';
import QCXFinalPercentualFieldRender from './QCXFinalPercentualFieldRender';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';

export default function QCXFaturaMercadoriaStandardFormGroup({
  tipo,
  modes,
  classes,
  isFilling,
  onBlurObjectHandlers,
  required = true,
  fatura,
  idFatura,
  isDIDUIMP,
  isTipoDeclaracaoDuimp,
  authInfo = {},
}) {
  /* eslint react/no-unknown-property: 0 */
  const { t } = useTranslation();
  const form = useForm();
  const { values } = useFormState();
  const dispatch = useDispatch();

  const { previousForm } = useGetApplicationState();

  const initializeForm = debounce(() => {
    // necesssario remover os id por conta do hibernate
    // talvez isso pudesse estar na normalize, mas tive medo de quebrar outras partes
    // by Tonim

    const atributos = (previousForm?.atributos || []).map((atributo) => ({ ...atributo, id: null }));

    form.initialize({
      ...previousForm,
      adicionais: { ...previousForm.adicionais, id: null },
      atributos,
      id: null,
      fabricante: isRelacaoFornecedorFabricanteExportador(previousForm?.relacao)
        ? previousForm?.exportador
        : previousForm?.fabricante,
    });
    dispatch(success());
  }, 1000);

  useEffect(() => {
    // Call the debounced function
    if (!previousForm) return () => {};
    if (previousForm?.loaded) return () => {};

    dispatch(loading());
    initializeForm();
    dispatch(setPreviousForm({ ...previousForm, loaded: true }));

    // Cleanup function to cancel the debounced call if the component unmounts
    return () => {};
  }, [previousForm, form, dispatch, initializeForm]);

  const { isConsult, isSubConsult, isSubNone } = modes;

  const isQuilograma = useMemo(
    () => values?.ncm?.unidadeDeMedida?.description === UNIDADE_DE_MEDIDA_QUILOGRAMA_LIQUIDO,
    [values]
  );

  const isQuantidade = useMemo(
    () =>
      values?.ncm?.unidadeDeMedida?.description === UNIDADE_DE_MEDIDA_UNIDADE && values?.unidadeDeMedida?.id === 200624,
    [values]
  );
  const isPeca = useMemo(
    () =>
      values?.ncm?.unidadeDeMedida?.description === UNIDADE_DE_MEDIDA_PECA && values?.unidadeDeMedida?.id === 355110,
    [values]
  );

  const {
    handleQuantidadeOnBlur,
    handleValorTotalMoedaOnBlur,
    handleValorUnitarioMoedaProdutoOnBlur,
    handlePesoLiquidoOnBlur,
    handlePesoLiquidoUnitarioOnBlur,
    handleDescontoPercentualOnBlur,
    handleDescontoValorOnBlur,
  } = onBlurObjectHandlers;

  const origemFaturaOcr = useMemo(() => fatura?.origem === 'OCR', [fatura]);

  const validatePendencias = useCallback(() => {
    if (!origemFaturaOcr || !values.id) return <></>;

    const pendencias = values?.pendencias || [];

    if (pendencias.length > 0) {
      return (
        <Grid xs={12} style={{ marginBottom: '2rem' }}>
          <QCXWarningAlert closable>
            <Trans i18nKey="com.muralis.qcx.mensagem.pendenciaOCRMercadoria">
              {/* Para alterar o texto, procure a chave pendenciaOCRMercadoria no arquivo JSON correspondente da tradução.
              Para a lib funcionar, precisa deixar o texto antes da lista dinâmica e das chaves que corrrespondem aos dados dinâmicos. */}
              Verifique as seguintes pendências detectadas pelo OCR:
              {{
                sequencial: values?.metadata?.sequencial || 'N/A',
              }}
              {{
                pagina: values?.metadata?.pagina || 'N/A',
              }}
              <ul i18nIsDynamicList>
                {pendencias.map((pendencia) => (
                  <li>{pendencia}</li>
                ))}
              </ul>
            </Trans>
          </QCXWarningAlert>
        </Grid>
      );
    }

    return <></>;
  }, [values]);

  return (
    <>
      {validatePendencias()}
      {isDIDUIMP ? (
        <Grid item container alignItems="center" spacing={2} xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <QCXFinalOnblurMaskedField
              id="text-field-numeroLI"
              key="text-field-numeroLI"
              name="numeroLI"
              label={t('com.muralis.qcx.mercadoria.numeroLI')}
              format={formatLINumber}
              parse={numberOnly}
              maxLength={11}
              disabled={isConsult || isSubConsult || isSubNone}
              initialValues={values}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <QCXFinalDatePickerField
              id="registro-date-field"
              key="registro-date-field"
              name="dataRegistro"
              label={t('com.muralis.qcx.mercadoria.dataRegistro')}
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>
        </Grid>
      ) : null}

      {isDIDUIMP && isTipoDeclaracaoDuimp ? (
        <Grid item container alignItems="center" spacing={2} xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <QCXFinalTextField
              id="text-field-codigoIntegracao"
              key="text-field-codigoIntegracao"
              name="codigoCatalogo"
              label="Cod. Integração"
              maxLength={11}
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <QCXFinalTextField
              id="text-field-versao"
              key="text-field-versao"
              name="versaoCatalogo"
              label="Versão"
              maxLength={11}
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>
        </Grid>
      ) : null}

      <Grid item xs={12} sm={12} md={6} lg={6}>
        <QCXSelectProdutoAutocomplete
          id="autocomplete-field-partnumber"
          key="autocomplete-field-partnumber"
          name="partnumberAutocompleteSelector"
          label={t('com.muralis.qcx.item.partNumber')}
          tipo={tipo}
          initialValues={values}
          form={form}
          disabled={isConsult || isSubConsult || isSubNone}
          partnumber={values?.partnumber}
          idFatura={idFatura}
          isDIDUIMP={isDIDUIMP}
          isTipoDeclaracaoDuimp={isTipoDeclaracaoDuimp}
        />
      </Grid>
      <Grid item container alignItems="center" xs={12} sm={12} md={6} lg={6}>
        <QCXFinalTextField
          id="text-field-descricao"
          key="text-field-descricao"
          name="descricaoResumida"
          label={t('com.muralis.qcx.mercadoria.descricaoResumida')}
          disabled={isConsult || isSubConsult || isSubNone}
          maxLength={50}
        />
      </Grid>
      <QCXRelacaoFornecedorFieldGroup
        isConsult={isConsult}
        isAlternativeReasonToDisabled={isSubConsult || isSubNone}
        relacao={values?.relacao}
        fabricante={values?.fabricante}
        exportador={values?.exportador}
        initialValues={values}
        authInfo={authInfo}
        autoCompleteProps={{
          externallyFillable: isFilling,
        }}
      />
      <Grid item container xs={12} sm={12} md={6} lg={6}>
        <Grid
          item
          style={{
            paddingBottom: '16px',
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXFinalNumericDecimalFieldRender
            id="numeric-decimal-quantidade-field"
            name="quantidade"
            label={t('com.muralis.qcx.quantidade.label')}
            onBlur={handleQuantidadeOnBlur(form, values)}
            disabled={isConsult || isSubConsult || isSubNone}
            validate={required && requiredValidator}
            scale={5}
            required={required && (Number.isNaN(Number(values?.quantidade)) || Number(values?.quantidade) <= 0)}
          />
        </Grid>
        <Grid
          item
          style={{
            paddingBottom: '16px',
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXSelectUnidadeMedidaAutocomplete
            id="select-unidade-medida-field"
            key="select-unidade-medida-field"
            name="unidadeDeMedida.id"
            label={t('com.muralis.qcx.unidadeMedida.label')}
            initialValues={values}
            disabled={isConsult || isSubConsult || isSubNone}
            fieldProps={required && { validate: requiredValidator }}
            externallyFillable={isFilling}
            required={required}
          />
        </Grid>
        <Grid
          item
          style={{
            paddingBottom: '16px',
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXFinalNumericDecimalFieldRender
            id="numeric-decimal-valor-unitario-field"
            name="valorUnitarioMoeda"
            label={t('com.muralis.qcx.valorUnitario')}
            disabled={isConsult || isSubConsult || isSubNone}
            scale={7}
            onBlur={() => handleValorUnitarioMoedaProdutoOnBlur(form, values)}
            validate={required && requiredValidator}
            required={
              required && (Number.isNaN(Number(values?.valorUnitarioMoeda)) || Number(values?.valorUnitarioMoeda) <= 0)
            }
          />
        </Grid>
        <Grid
          item
          style={{
            paddingBottom: '16px',
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXSelectFormaCalculoAutocomplete
            id="autocomplete-select-forma-calculo-field"
            key="autocomplete-select-forma-calculo-field"
            name="tipoCalculo"
            onBlur={() => handleValorUnitarioMoedaProdutoOnBlur(form, values)}
            label={t('com.muralis.qcx.formaCalculo')}
            initialValues={values}
            disabled={isConsult || isSubConsult || isSubNone}
          />
        </Grid>
        <Grid
          item
          style={{
            paddingBottom: '16px',
          }}
          xs={12}
          sm={12}
          md={6}
          lg={6}
        >
          <QCXFinalPercentualField
            id="percentual-desconto-field"
            key="percentual-desconto-field"
            name="percentualDesconto"
            onBlur={handleDescontoPercentualOnBlur(form, values)}
            label={t('com.muralis.qcx.porcentagemDesconto')}
            disabled={isConsult || isSubConsult || isSubNone}
          />
        </Grid>
        <Grid
          item
          style={{
            paddingLeft: '16px',
            paddingBottom: '16px',
          }}
          xs={12}
          sm={12}
          md={6}
          lg={6}
        >
          <QCXFinalAlternativeCurrencyFieldRender
            id="valor-desconto-field"
            key="valor-desconto-field"
            name="valorDescontoMoeda"
            onBlur={handleDescontoValorOnBlur(form, values)}
            label={t('com.muralis.qcx.valorDesconto')}
            disabled={isConsult || isSubConsult || isSubNone}
          />
        </Grid>
        <Grid
          item
          style={{
            paddingBottom: '16px',
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXFinalAlternativeCurrencyFieldRender
            scale={7}
            id="currency-valor-total-item-field"
            name="valorTotalMoeda"
            onBlur={handleValorTotalMoedaOnBlur(form, values)}
            label={t('com.muralis.qcx.mercadoria.valorTotalItem')}
            disabled={isConsult || isSubConsult || isSubNone}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <QCXFinalPercentualFieldRender
            id="percentual-icms-field"
            name="icms"
            label={t('com.muralis.qcx.aliquotaICMS')}
            disabled={isConsult || isSubConsult || isSubNone}
            scale={7}
          />
        </Grid>
        <Grid item className={classes.destaqueNcmFieldLeft} xs={12} sm={12} md={6} lg={6}>
          <QCXFinalTextField
            id="text-field-destaque-ncm"
            key="text-field-destaque-ncm"
            name="destaqueNcm"
            label={t('com.muralis.qcx.NCM.destaqueNCM')}
            disabled={isConsult || isSubConsult || isSubNone}
            maxLength={3}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={12} md={6} lg={6}>
        <QCXNcmNaladiFormGroup modes={modes} isFilling={isFilling} />
        <Grid
          item
          style={{
            paddingBottom: '16px',
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXFinalNumericDecimalFieldRender
            id="numeric-decimal-peso-liquido-unitario-field"
            name="pesoLiquidoUnitario"
            onBlur={handlePesoLiquidoUnitarioOnBlur(form, values)}
            label="Peso Líquido Unitário"
            disabled={isConsult || isSubConsult || isSubNone}
            scale={12}
          />
        </Grid>
        <Grid
          item
          style={{
            paddingBottom: '16px',
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXFinalNumericDecimalFieldRender
            id="numeric-decimal-peso-liquido-field"
            name="pesoLiquido"
            onBlur={handlePesoLiquidoOnBlur(form, values)}
            label={t('com.muralis.qcx.pesoLiquido')}
            disabled={isConsult || isSubConsult || isSubNone}
            scale={7}
          />
        </Grid>
        <Grid
          item
          style={{
            paddingBottom: '16px',
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXFinalTextField
            id="text-unidade-na-medida-field"
            key="text-unidade-na-medida-field"
            name="ncm.unidadeDeMedida.description"
            label={t('com.muralis.qcx.unidadeMedida.unidadeMedidaEstatistica')}
            disabled
          />
        </Grid>
        <Grid
          item
          style={{
            paddingBottom: '16px',
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXFinalNumericDecimalField
            id="numeric-decimal-quantidade-estatistica-field"
            key="numeric-decimal-quantidade-estatistica-field"
            name="quantidadeEstatistica"
            label={t('com.muralis.qcx.quantidade.quantidadeEstatistica')}
            disabled={isConsult || isSubConsult || isSubNone || isQuilograma || isPeca || isQuantidade}
            scale={isQuantidade ? 5 : 7}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <QCXFinalAlternativeCurrencyField
          id="currency-valor-total-mcv-item-field"
          key="currency-valor-total-mcv-item-field"
          name="valorTotalMcvMoeda"
          label={t('com.muralis.qcx.mercadoria.valorTotalMCVItem')}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <QCXFinalNumericIntegerField
          id="text-field-quebra-auxiliar"
          key="text-field-quebra-auxiliar"
          name="quebraAuxiliar"
          label={t('com.muralis.qcx.mercadoria.quebraAdicional')}
          disabled={isConsult || isSubConsult || isSubNone}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3} style={{ paddingTop: '1.5vh' }}>
        <QCXFinalCheckboxField
          id="checkbox-tipo-field"
          key="checkbox-tipo-field"
          name="necessitaLI"
          label="Necessita LI"
          disabled={isConsult}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <QCXFinalMultilineTextField
          id="text-especificacao-multiline-field"
          key="text-especificacao-multiline-field"
          name="adicionais.especificacao"
          label={t('com.muralis.qcx.especificacao.especificacaoMercadoria')}
          disabled={isConsult || isSubConsult || isSubNone}
          rows={5}
          maxLength={3900}
          helperText={t('com.muralis.qcx.validacao.caracteresEspeciaisNaoPermitidos')}
          validate={required && requiredValidator}
          required={required}
        />
      </Grid>
    </>
  );
}
